import Table from "react-bootstrap/Table";
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableColumn,
} from "mui-datatables";
import { useMediaQuery } from "@mui/material";
import Card from "react-bootstrap/Card";
import {
  Button,
  Container,
  Form,
  Modal,
  Navbar,
  Row,
  Spinner,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import BASE_URL from "../constants/baseURL";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import DefaultSidebar from "../components/sidebar";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Col from "react-bootstrap/Col";
import { Chip } from "@mui/material";
import { LockResetOutlined } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import { LinearProgress, Typography } from "@mui/material";
import ProgressBar from "../components/common/progressbar";
import "./index.css";
import Header from "../components/common/header";
import { staticRoles } from "../utils/constants";
const {SUPER_ADMIN,ADMIN_USER} = staticRoles

const Dashboard = () => {
  const [show, setShow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [showUpdateInfo, setShowUpdateInfo] = useState(false);
  const [showBeneForm, setShowBeneForm] = useState(false);
  const [showConfirmForm, setShowConfirmForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [iban, setIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [accountName, setAccountName] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [beneAccountNumber, setBeneAccountNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [currency, setCurrency] = useState("");
  const [uuid, setUuid] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [item, setItem]: any = useState("");
  const [updateItem, setUpdateItem]: any = useState(null);
  const [beneficiaryInfo, setBeneficiaryInfo]: any = useState("");
  const [beneficiaryGuid, setBeneficiaryGUID] = useState("");
  const [userFirstName, setuserFirstName] = useState("");
  const [userLastName, setuserLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [userRoles, setUserRoles] = useState<string[]>([]);
  const [roles, setRoles] = useState([]);
  const [showRolesInfo, setShowRolesInfo] = useState(false);

  const [userList, setUserList]: any = useState([]);
  const [accountList, setAccountList]: any = useState([]);
  const [selectedRows, setSelectedRows]: any = useState([]);
  const [benName, setBenName] = useState("");
  const [dataIndex, setDataIndex] = useState(-1);
  // create state for progress bar
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [isLoadingSaveChange, setIsLoadingSaveChange] = useState(false);
  const [progress, setProgress] = useState(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const Token: any = localStorage.getItem("token");
  const loggedInUserRoles: string[] = JSON.parse(localStorage.getItem('roles') || "[]");


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleClose = () => setShow(false);
  const handleCloseDetails = () => setShowDetails(false);
  const handleCloseUpdateInfo = () => setShowUpdateInfo(false);
  const theme = useTheme();
  
  const handleShowRoleInfo = () => {
    // if modal i open then at close time we check if something is changes then refresh the data otherwise close the modal
    if(showRolesInfo ===true){ 
      const parsedUserRoles = userRoles.map((role) => JSON.parse(role)._id);
      const sortedItemRoles = [...item.roles].sort();
    const sortedParsedUserRoles = [...parsedUserRoles].sort();
      const hasRoleChanges =
      sortedItemRoles.length !== sortedParsedUserRoles.length ||
      sortedItemRoles.some((roleId, index) => roleId !== sortedParsedUserRoles[index]);
      if (hasRoleChanges) {
        console.log("Role changes detected. Fetching API... =>");
        fetchMyAPI();
      } else {
        console.log("No role changes detected. =>");
      }

      setItem('')
    }
    setShowRolesInfo(!showRolesInfo);
  };
  const handleRoleChnage = (e: any) => {
    const {
      target: { value },
    } = e;
    setUserRoles(value);
  };

  function getStyles(name: any, personName: any, theme: any) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleShowUserInfoUpdate = (item: any) => {
    setuserFirstName(item?.firstName);
    setuserLastName(item?.lastName);
    setEmail(item?.email);
    setPhone(item?.phoneNo);
    setItem(item);
    setUpdateItem(item);
    setShowUpdateInfo(true);
  };

  const handleShow = (item: any) => {
    setItem(item);
    setShow(true);
  };
  const handleCloseBeneForm = () => setShowBeneForm(false);
  const handleShowBeneForm = (item: any) => {
    setBeneficiaryInfo(item);
    setShowBeneForm(true);
  };
  const handlecloseConfirmForm = () => setShowConfirmForm(false);
  const handleshowConfirmForm = () => {
    fetchBeneAPI();
    setShowConfirmForm(true);
  };
  const handleShowDetails = () => {
    setShowDetails(true);
  };
  const handleUpdateItemChange = (key: string, value: any) => {
    let obj = {
      ...updateItem,
      [`${key}`]: value,
    };
    setUpdateItem(obj);
  };

  const getRoles = async () => {
    try {
      let res = await axios.get(`${BASE_URL}role`);
      if (res?.status === 200 && res?.data?.data) setRoles(res.data.data);
    } catch (error) {
      console.log("Error ", error);
    }
  };
  const handleDelete = (event: React.MouseEvent, roleId: string) => {
    setUserRoles((prevRoles: any[]) => 
      prevRoles.filter((role: any) => {
        const parsedRole = JSON.parse(role);
        return parsedRole._id !== roleId;
      })
    );
  };

  const submitUpdateRoleForm = async () => {
    try {
      setIsLoadingSaveChange(true)
      const userObj = {
        roles: userRoles.map((el) => JSON.parse(el)?._id),
      };

      const response: any = await axios.put(
        `${BASE_URL}user/update/${item._id}`,
        userObj,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "Updated !",
          text: "User has been updated successfully",
        });
      }, 100);
      // fetchMyAPI();
      setIsLoadingSaveChange(false)
      // handleShowRoleInfo();
      // setItem(""); commenting this becuae we we allow user to add more roles and save changes and if we do not comment this then id will be undefiled in update api call
    } catch (err: any) {
      console.log(err.response.data.message);
      setIsLoadingSaveChange(false)
      //  handleClose();
      setTimeout(() => {
        alert(err.response.data.message);
      }, 100);
    }
  };

  const submitUpdateInfoForm = async (e: any) => {
    e.preventDefault();
    try {
      const userObj = {
        ...updateItem,
        email: email,
        phoneNo: phone,
        firstName: userFirstName,
        lastName: userLastName,
      };
      delete userObj._id;
      delete userObj.companyID;
      delete userObj.token;
      delete userObj.attempts;
      delete userObj.password;
      delete userObj.activeStatus;
      delete userObj.key;
      delete userObj.verified;

      console.log("USER OBJ ", userObj);
      const response: any = await axios.put(
        `${BASE_URL}user/update/${item._id}`,
        userObj,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setTimeout(() => {
        alert("Success");
      }, 100);
      handleCloseUpdateInfo();
      fetchMyAPI();
    } catch (err: any) {
      console.log(err.response.data.message);
      //  handleClose();
      setTimeout(() => {
        alert(err.response.data.message);
      }, 100);
    }
  };
  const handleSaveClick = (e: any) => {
    e.preventDefault();
    setIsConfirmModalOpen(true);
    setShowConfirmation(true);
  };
  const handleConfirmNo = () => {
    setShowConfirmation(false);
    setIsConfirmModalOpen(false);
  };

    const submitForm = async (e: any) => {
        e.preventDefault();
        try {
            const response: any = await axios.post(`${BASE_URL}activeUser`, {
                bank: bankName,
                bankAddress,
                userID: item._id,
                accB4BUUID: uuid,
                IBAN: iban,
                mambuClientId: merchantId,
                accountName,
                accountNumber,
                sortCode,
                swiftCode,
                currency
            }, {
                headers: {
                    "Authorization": `Bearer ${Token}`
                }
            });
            setTimeout(() => {
                alert('Success')
            }, 100);
            handleClose();
            fetchMyAPI();
        }
        catch (err: any) {
            console.log(err.response.data.message);
            //  handleClose();
            setTimeout(() => {
                alert(err.response.data.message)
            }, 100);
        } finally {
          setBankName("");
          setBankAddress("");
          setAccountName("");
          setIban("");
          setMerchantId("");
          setUuid("");
          setSortCode("");
          setAccountNumber("");
          setSwiftCode("");
          setCurrency("");
          setIsConfirmModalOpen(false);
          setShowConfirmation(false);
          
        }
    }
    const submitBeneForm = async (e: any) => {
        e.preventDefault();
        try {
            const response: any = await axios.post(`${BASE_URL}beneficiary/addBeneficiaryManual/${beneficiaryGuid}`, {
                uuid: beneficiaryInfo.accB4BUUID,
                accID: beneficiaryInfo._id,
                account: beneAccountNumber
            }, {
                headers: {
                    "Authorization": `Bearer ${Token}`
                }
            });
            setBenName(response.data.name)
            setTimeout(() => {
                alert('Success')
            }, 100);
            handleClose();
        }
        catch (err: any) {
            console.log(err.response.data.message);
            //  handleClose();
            setTimeout(() => {
                alert(err.response.data.message)
            }, 100);
        }
    }
    async function fetchMyAPI() {
      setLoadingUsers(true);
      setInitialLoading(true);
      setProgress(0);
  
      const updateProgress = (event: any) => {
        if (event.total) {
          setProgress(Math.round((event.loaded / event.total) * 100));
        }
      };
  
      try {
        const user: any[] = [];
        const response = await axios.get(`${BASE_URL}user/getAllUsers`, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
          onDownloadProgress: updateProgress, // Track download progress
          onUploadProgress: updateProgress, // Track upload progress if applicable
        });
  
        response.data.data.forEach((element: any) => {
          user.push(element);
        });
  
        setUserList(user);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setProgress(100);
        setLoadingUsers(false);
        setInitialLoading(false);
      }
    }
    async function ResetQrApi(userdata: any, userId: any) {

        userdata.verified = false
        const newUser = userdata
        delete newUser._id
        delete newUser.companyID
        console.log("clicked", userdata)
        const response: any = await axios.put(`${BASE_URL}user/update/${userId}`, {
            verified: false,
            // headers: {
            //     "Authorization": `Bearer ${Token}`
            // },
            // data: { "verified": false }
        },{
          headers: {
              "Authorization": `Bearer ${Token}`
          },
      });
        if (response.data.data) {
            fetchMyAPI();
            alert("Qr Reset")
        } else {

            alert("error")
        }
    }
    async function ResetAccountApi(userdata: any, userId: any) {

        userdata.attempts = 0
        const newUser = userdata
        delete newUser._id
        delete newUser.companyID
        console.log("clicked", userdata)
        const response: any = await axios.put(`${BASE_URL}user/update/${userId}`, {
            attempts: 0,
            // headers: {
            //     "Authorization": `Bearer ${Token}`
            // },
            // data: { "attempts": 0 }
        },{
          headers: {
              "Authorization": `Bearer ${Token}`
          },
      });
        if (response.data.data) {
            fetchMyAPI();
            alert("Account reset Successfully")
        } else {

            alert("error")
        }
    }
    const usersHeader = [
        { name: "email", label: "Email" },
        { name: "phoneNo", label: "Phone No" },
        {
            name: "companyID.companyName", label: "Company", options: {
                customBodyRenderLite: (dataIndex: any) => {
                    return <><div>{userList[dataIndex]?.companyID?.companyName}</div></>
                },
                enableNestedDataAccess: '.'
            }
        },
        {
            name: "activeStatus", label: "Status", options: {
                // setCellProps: (value: any) => {
                //     return {
                //         style: {
                //             color: value ? 'green' : '',
                //         },

        //     };
        // },
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <Chip
                disabled={!userList[dataIndex].activeStatus}
                color="secondary"
                icon={<InfoIcon />}
                onClick={() => {
                  setSelectedRows([]);
                  setAccountList([]);
                  handleShowDetails();
                  setDataIndex(dataIndex);
                  fetchAllUserAccountsAPI(userList[dataIndex]._id);
                }}
                label="Details"
              />
            </>
          );
          {
            /* <button disabled={!userList[dataIndex].activeStatus} onClick={() => {
                        setSelectedRows([]);
                        setAccountList([]);
                        handleShowDetails()
                        fetchAllUserAccountsAPI(userList[dataIndex]._id)
                    }
                    }>
                        Details
                    </button> */
          }
        },
      },
    },
    { name: "createdAt", label: "Created" },
    {
      name: "activeStatus",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <Chip
                color="info"
                icon={<AddIcon />}
                onClick={() => {
                  handleShow(userList[dataIndex]);
                }}
                label="Add account"
              />
            </>
          );
          // <><button onClick={() => { handleShow(userList[dataIndex]) }}>
          //     Add Account
          // </button></>
        },
      },
    },
    {
      name: "verified",
      label: "QRVerified",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <Chip
                disabled={
                  // !userList[dataIndex].verified
                  !userList[dataIndex].verified || // If user is not verified, disable for everyone
                  (userList[dataIndex].verified && !loggedInUserRoles.some(role => [SUPER_ADMIN, ADMIN_USER].includes(role))) // If verified, allow only ADMIN or SUPER_ADMIN
                }
                color="default"
                icon={<RestartAltIcon />}
                onClick={async () => {
                  await ResetQrApi(
                    userList[dataIndex],
                    userList[dataIndex]._id
                  );
                }}
                label={userList[dataIndex].verified ? "ResetQR" : "Not Scaned"}
              />
            </>
          );
        },
      },
    },
    {
      name: "verified",
      label: "Account Recovery",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <Chip
                disabled={
                  // userList[dataIndex].attempts === 0 ? true : false
                  userList[dataIndex].attempts === 0 || // Disable if attempts are 0
                 (userList[dataIndex].attempts > 0 && !loggedInUserRoles.some(role => [SUPER_ADMIN, ADMIN_USER].includes(role))) // Disable if user is not ADMIN or SUPER_ADMIN
                  
                }
                color="default"
                icon={<LockResetOutlined />}
                onClick={async () => {
                  await ResetAccountApi(
                    userList[dataIndex],
                    userList[dataIndex]._id
                  );
                }}
                label={
                  userList[dataIndex].attempts > 0
                    ? "Reset Attemps"
                    : "Enough Attemps"
                }
              />
            </>
          );
        },
      },
    },
    {
      name: "Update Info",
      label: "Update Info",
      options: {
        customBodyRenderLite: (dataIndex: any) => {
          return (
            <>
              <Chip
                color="info"
                onClick={() => {
                  handleShowUserInfoUpdate(userList[dataIndex]);
                }}
                label="Update"
              />
            </>
          );
        },
      },
    },
    // /* Commented for Some time
        {
            name: "Roles", label: "Roles", options: {
                display:loggedInUserRoles?.some(role => [SUPER_ADMIN].includes(role)),
                customBodyRenderLite: (dataIndex: any) => {
                  if(loggedInUserRoles?.some(role => [SUPER_ADMIN].includes(role))){
                    return <><Chip color='info' onClick={() => {
                      const rolesObj: any = [];
                      roles.forEach((el: any) => {
                          if(userList[dataIndex]?.roles?.includes(el?._id)) {
                              rolesObj.push(JSON.stringify(el))
                          }
                      })
                      setItem(userList[dataIndex]);
                      setUserRoles(rolesObj);
                      handleShowRoleInfo(); }}
                      disabled={!loggedInUserRoles?.some(role => [SUPER_ADMIN].includes(role))}
                      label="Update"
                       /></>
                  }
                    
                }
            }
        }
  ];

  // const options = {

  //     enableNestedDataAccess: '.', // allows nested data separated by "." (see column names and the data structure above)

  //   };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const options: MUIDataTableOptions = {
    enableNestedDataAccess: ".",
    responsive: "standard", 
    tableBodyHeight: isMobile ? "calc(100vh - 320px)" : "auto",
    tableBodyMaxHeight: "",
    // selectableRows: 'none',
    // rowsPerPageOptions: [10, 20, 30],
    rowsPerPage: 10,
    downloadOptions: { filename: "users.csv", separator: "," },
    filterType: "dropdown",
  };
  async function fetchBeneAPI() {
    const response: any = await axios.get(
      `${BASE_URL}beneficiary/${beneficiaryGuid}`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        data: {
          uuid: beneficiaryInfo.accB4BUUID,
        },
      }
    );
    console.log(response.data.name);
    setBenName(response.data.name);
  }
  async function fetchAllUserAccountsAPI(B4BUUID: string) {
    const accounts: any[] = [];
    const response: any = await axios.get(
      `${BASE_URL}activeUser?userID=${B4BUUID}`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      }
    );
    setAccountList(response.data.data);
    console.log(accountList);
  }

  async function deleteUsersAccountsAPI(B4BUUID: string, inactive: Boolean) {
    var body;
    if (inactive) {
      body = { inactive: false };
    } else {
      body = { inactive: true };
    }
    const response: any = await axios.patch(
      `${BASE_URL}activeUser/delete-account/${B4BUUID}`,
      {
        ...body,
      }
    );
    return response.status;
  }

 
  useEffect(() => {
    fetchMyAPI();
    getRoles();
  }, []);

//   

  return (
    <Container fluid className="App">
      <Row>
        <Col
          style={{
            backgroundColor: "white",
            minHeight: "",
            color: "white",
          }}
          lg={2}
        >
          <DefaultSidebar />
        </Col>
        <Col
          style={{
            backgroundColor: "#ececec",
          }}
          sm={12}
          xl={10}
          xs={12}
          md={12}
          lg={10}
        >
         <Header/>
          {/* <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>email</th>
                                <th>phone no</th>
                                <th>activeStatus</th>
                                <th>createdAt</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userList && userList.map((ele: any) => {
                                return (
                                    <>
                                        <tr onClick={() => { console.log(ele._id) }} >
                                            <td>{ele.email}</td>
                                            <td>{ele.phoneNo}</td>
                                            <td>{ele.activeStatus.toString() == 'true' ? 'Active' : 'Inactive'}</td>
                                            <td>{ele.createdAt}</td>
                                            <div style={{ display: 'flex', alignItems: 'space-around', justifyContent: 'space-evenly', width: '100%' }}>
                                                <td>
                                                    <Button variant="primary" onClick={() => {
                                                        handleShow(ele)
                                                    }}>Add Account</Button>
                                                    <Button
                                                        disabled={ele.activeStatus.toString() === 'true' ? false : true}
                                                        style={{ marginLeft: 10 }} variant="primary" onClick={() => {
                                                            setSelectedRows([]);
                                                            setAccountList([]);
                                                            handleShowDetails()
                                                            fetchAllUserAccountsAPI(ele._id)
                                                        }}>Details</Button>
                                                </td>
                                            </div>

                                        </tr>
                                    </>
                                )
                            })

                            }
                        </tbody>
                    </Table> */}
          
          {initialLoading || loadingUsers ? (
            <div
              className="d-flex justify-content-between gap-2  align-items-center"
              style={{ alignItems: "flex-start" }}
            >
              <ProgressBar progress={progress} />
              <Typography>{progress}%</Typography>
            </div>
          ) : !loadingUsers && userList.length === 0 ? (
            <h3>Oops, something went wrong</h3>
          ) : (
            <div style={{ margin: 10 }}>
              <MUIDataTable
                title={"Users List"}
                data={userList}
                columns={usersHeader}
                options={options}
              />
            </div>
          )}
           {/* Modal user details */}
          <Modal size="xl" show={showDetails} onHide={handleCloseDetails}>
            <Modal.Header closeButton>
              <Modal.Title>User Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{}}>
                <Table
                  border={1}
                  style={{ overflow: "hidden", textAlign: "center" }}
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <th>Account Name</th>
                      <th>B4B UUID</th>
                      <th>IBAN</th>
                      <th>Bank</th>
                      <th>Currency</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  {accountList &&
                    accountList.map((ele: any) => {
                      return (
                        <>
                          <tr
                            style={{
                              fontSize: 13,
                              backgroundColor:
                                selectedRows.includes(ele._id) || ele.deletedAt
                                  ? "grey"
                                  : "transparent",
                            }}
                          >
                            <td style={{ padding: 20 }}>{ele.accountName}</td>
                            <td>{ele.accB4BUUID}</td>
                            <td>{ele.IBAN}</td>
                            <td>{ele.bank}</td>
                            <td>{ele.currency}</td>
                            {/* <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "space-around",
                                  justifyContent: "space-evenly",
                                  width: "100%",
                                }}
                              >
                                <Button
                                  // disabled={ele.deletedAt ? true : false}
                                  style={
                                    ele.inactive
                                      ? {
                                          backgroundColor: "blue",
                                          borderColor: "blue",
                                          margin: 10,
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }
                                      : {
                                          backgroundColor: "red",
                                          borderColor: "red",
                                          margin: 10,
                                          fontSize: 10,
                                          fontWeight: "bold",
                                        }
                                  }
                                  onClick={async () => {
                                    const status = await deleteUsersAccountsAPI(
                                      ele._id,
                                      ele.inactive
                                    );
                                    if (status == 200) {
                                      fetchAllUserAccountsAPI(
                                        userList[dataIndex]._id
                                      );
                                    }
                                    // if (selectedRows.includes(ele._id) || ele.inactive) {
                                    //     console.log("Already Inside List")
                                    // }
                                    // else {
                                    //     const status = await deleteUsersAccountsAPI(ele._id, ele.inactive)
                                    //     if (status == 200) {
                                    //         setSelectedRows((oldArray: any) => [...oldArray, ele._id])
                                    //     }
                                    // }
                                  }}
                                >
                                  {ele.inactive ? "Restore" : "Delete"}
                                </Button>
                                <Button
                                  style={{
                                    backgroundColor: "transparent",
                                    borderColor: "blue",
                                    margin: 10,
                                    color: "blue",
                                    fontSize: 10,
                                    fontWeight: "bold",
                                  }}
                                  onClick={async () => {
                                    handleShowBeneForm(ele);
                                  }}
                                >
                                  {" "}
                                  Add Beneficiary
                                </Button>
                              </div>
                            </td> */}
                          </tr>
                        </>
                      );
                    })}
                </Table>
              </div>
            </Modal.Body>
          </Modal>
          {/* Modal Add Account */}
          <Modal style={{visibility: isConfirmModalOpen ? "hidden" : "visible"}} show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSaveClick}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="bankName">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Bank Name"
                    onChange={(e: any) => setBankName(e.target.value)}
                    required={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="bankAdress">
                  <Form.Label>Bank Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Bank Address"
                    onChange={(e: any) => setBankAddress(e.target.value)}
                    required={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="accountName">
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Account Name"
                    onChange={(e: any) => setAccountName(e.target.value)}
                    required={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="iban">
                  <Form.Label>IBAN</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="IBAN"
                    onChange={(e: any) => setIban(e.target.value)}
                    required={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="mambuClientId">
                  <Form.Label>Mambu Client Id</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Mambu Client Id"
                    onChange={(e: any) => setMerchantId(e.target.value)}
                    required={false}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="accountUuid">
                  <Form.Label>Account Uuid</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Account Uuid"
                    onChange={(e: any) => setUuid(e.target.value)}
                    required={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="sortCode">
                  <Form.Label>Sort Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sort Code"
                    onChange={(e: any) => setSortCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="accountNumber">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Account Number"
                    onChange={(e: any) => setAccountNumber(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="swiftCode">
                  <Form.Label>Swift Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Swift Code"
                    onChange={(e: any) => setSwiftCode(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="currency">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Currency"
                    onChange={(e: any) => setCurrency(e.target.value)}
                    required={true}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} type="button">
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  {isLoadingSaveChange ? <Spinner animation={"border"} size='sm' /> : "Save Changes"}
                  
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmation} onHide={handleConfirmNo}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Bank Name:</strong> {bankName}
          </p>
          <p>
            <strong>Bank Address:</strong> {bankAddress}
          </p>
          <p>
            <strong>Account Name:</strong> {accountName}
          </p>
          <p>
            <strong>IBAN:</strong> {iban}
          </p>
          <p>
            <strong>Mambu Client Id:</strong> {merchantId}
          </p>
          <p>
            <strong>Account Uuid:</strong> {uuid}
          </p>
          <p>
            <strong>Sort Code:</strong> {sortCode}
          </p>
          <p>
            <strong>Account Number:</strong> {accountNumber}
          </p>
          <p>
            <strong>Swift Code:</strong> {swiftCode}
          </p>
          <p>
            <strong>Currency:</strong> {currency}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleConfirmNo}
          >
            No
          </Button>
          <Button variant="primary" onClick={submitForm}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
          {/* Modal Add BeneFiciary into */}
          <Modal show={showBeneForm} onHide={handleCloseBeneForm}>
            <Modal.Header closeButton>
              <Modal.Title
                style={{
                  fontSize: 15,
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                Add BeneFiciary into ({beneficiaryInfo.accountName})
              </Modal.Title>
            </Modal.Header>
            <Form>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="accountUuid">
                  <Form.Label>Beneficiary guid</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Beneficiary guid"
                    onChange={(e: any) => setBeneficiaryGUID(e.target.value)}
                    required={true}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="accountNumber">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Account Number"
                    onChange={(e: any) => setBeneAccountNumber(e.target.value)}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseBeneForm}
                  type="button"
                >
                  Close
                </Button>
                <Button variant="primary" onClick={handleshowConfirmForm}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          {/* Modal */}
          <Modal show={showConfirmForm} onHide={handlecloseConfirmForm}>
            <Modal.Body>
              <h1>Are you sure?</h1>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handlecloseConfirmForm}
                type="button"
              >
                No
              </Button>
              <Button variant="primary" onClick={submitBeneForm}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Modal Update Info */}
          <Modal show={showUpdateInfo} onHide={handleCloseUpdateInfo}>
            <Modal.Header closeButton>
              <Modal.Title>Update Info</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitUpdateInfoForm}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    onChange={(e: any) => setuserFirstName(e.target.value)}
                    required={true}
                    value={userFirstName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    onChange={(e: any) => setuserLastName(e.target.value)}
                    required={true}
                    value={userLastName}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    required={true}
                    value={email}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone#</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone#"
                    onChange={(e: any) => setPhone(e.target.value)}
                    required={true}
                    value={phone}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    onChange={(e: any) =>
                      handleUpdateItemChange("city", e.target.value)
                    }
                    required={true}
                    value={updateItem?.city}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    onChange={(e: any) =>
                      handleUpdateItemChange("country", e.target.value)
                    }
                    required={true}
                    value={updateItem?.country}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="postalCode">
                  <Form.Label>Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Postal Code"
                    onChange={(e: any) =>
                      handleUpdateItemChange("postalCode", e.target.value)
                    }
                    required={true}
                    value={updateItem?.postalCode}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="countryCode">
                  <Form.Label>Country Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Country Code"
                    onChange={(e: any) =>
                      handleUpdateItemChange("countryCode", e.target.value)
                    }
                    required={true}
                    value={updateItem?.countryCode}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="ledgerNumber">
                  <Form.Label>Ledger Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ledger Number"
                    onChange={(e: any) =>
                      handleUpdateItemChange("ledgerNumber", e.target.value)
                    }
                    value={updateItem?.ledgerNumber}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="companyRegNumber">
                  <Form.Label>Company Registration Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Registration Number"
                    onChange={(e: any) =>
                      handleUpdateItemChange("companyRegNumber", e.target.value)
                    }
                    value={updateItem?.companyRegNumber}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="payex_auth_token">
                  <Form.Label>Payex Token</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Payex Token"
                    onChange={(e: any) =>
                      handleUpdateItemChange("payex_auth_token", e.target.value)
                    }
                    value={updateItem?.payex_auth_token}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleCloseUpdateInfo}
                  type="button"
                >
                  Close
                </Button>
                <Button variant="primary" type="submit">
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          {/* Modal Update Roles */}
          <Modal show={showRolesInfo} onHide={handleShowRoleInfo}>
            <Modal.Header closeButton>
              <Modal.Title>Update Roles</Modal.Title>
            </Modal.Header>
            <Form onSubmit={submitUpdateInfoForm}>
              <Modal.Body>
                <Form.Group className="mb-3" controlId="slug">
                  <Form.Label>Roles</Form.Label>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    label="Select Role"
                    fullWidth
                    multiple
                    value={userRoles}
                    onChange={handleRoleChnage}
                    input={<OutlinedInput id="select-multiple-chip" label="" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => {
                          console.log("VALUE ", value);
                          const parseValue = JSON.parse(value);
                          return (
                            <Chip
                              key={parseValue?._id}
                              label={parseValue?.name}
                              onDelete={(event) => handleDelete(event, parseValue?._id)}
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          );
                        })}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {roles.map((el: any) => (
                      <MenuItem
                        key={el?._id}
                        value={JSON.stringify(el)}
                        style={getStyles(el?.name, [], theme)}
                      >
                        {el?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={handleShowRoleInfo}
                  type="button"
                >
                  Close
                </Button>
                <Button variant="primary" onClick={submitUpdateRoleForm}>
                  {isLoadingSaveChange ? <Spinner animation={"border"} size='sm' />  : "Save Changes"} 
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
